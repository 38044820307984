(function ($) {
    'use strict';

    /*Params sent from PHP will be inn MyPluginParamsAdmin*/
    $(document).ready(function ($) {

        // console.log(MyPluginParamsAdmin)
        if ($(".checkrobin #dataTable").length > 0) {
            var crDataTable = $('.checkrobin #dataTable').DataTable({
                stateSave: true,
                order:[[1,"desc"]],
                "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "Alle"]],
                "language": {
                    "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/German.json"
                }
            });

            // Sort by columns 1 and redraw
            crDataTable.order( [[ 1, 'desc' ]] ).draw();
        }

        // Workaround: Use this tro prevent redirecting to jetpack
        $('html .checkrobin .settings').bind('keypress', function(e) {
            if (e.keyCode == 13) {
                $('.woocommerce-save-button').click();
                return false;
            }
        });

    }); //END document ready

    /*
     * AJAX functions
     * */

})(jQuery);
